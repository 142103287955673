import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Use alt attribute properly for accessibility and SEO",
  "description": "Every image needs an alternative text. Learn how to use it and what benefits come with well written alt attributes.",
  "lead": "Every image needs an alternative text. Learn how to use it and what benefits come with well written alt attributes.",
  "dateCreated": "2021-09-18T00:00:00.000Z",
  "tags": "HTML",
  "slug": "use-alt-attribute-properly-for-accessibility-and-seo",
  "postUrl": "https://uselessdivs.com/blog/use-alt-attribute-properly-for-accessibility-and-seo/",
  "ogImg": "https://uselessdivs.com/img/use-alt-attribute-properly-for-accessibility-and-seo/og-img.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<img src="image.png" alt="The picture description comes here." />`}</code></p>
    <p>{`Many people miss alternative texts and many misuse them. This is a big mistake, because `}<strong parentName="p">{`they have a huge effect on accessibility and even on SEO`}</strong>{`. Here are 4 reasons why you should pay more attention to writing alternative texts.`}</p>
    <h2>{`#1 Image accessibility`}</h2>
    <p>{`Screen reader users are mainly blind or partially sighted people. The main reason for using alt attribute is to describe the images to them. When a screen reader comes to an image it will read the value of the alt attribute for your visitor.`}</p>
    <h2>{`#2 Fallback text, if image doesn't load`}</h2>
    <p>{`A lot of things can cause an image not to load on a websites. For example: incorrect file path, missing file, too large image size or bad internet connection.`}</p>
    <p>{`In these cases, the `}<em parentName="p">{`alternative text comes and saves the day`}</em>{` because it appears in place of the missing images.`}</p>
    <h2>{`#3 Help people to find your images with search engines`}</h2>
    <p>{`When a search engine crawls through your website, it checks your images too and they will index your images based on your alt attributes.
So if you write good alternative text and your image appears in search results, `}<strong parentName="p">{`it can bring you more visitors`}</strong>{`.`}</p>
    <h2>{`#4 Add keywords to your homepage`}</h2>
    <p>{`When people are looking for something on the internet, they start typing in search engines. The search engines will show them websites that containing the keywords they are searching for. `}<em parentName="p">{`The more keywords you have on your website, the better your page will rank in the search results.`}</em>{`
And here comes the best part: every single word you write in alt attribute counts as a keyword, so basically you are `}<strong parentName="p">{`improving your search engine optimization`}</strong>{` (SEO).`}</p>
    <p>{`Now that we've learned the benefits of a well-written alternative text, it's time to dive into best practices.`}</p>
    <h2>{`Best practices:`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Max. 125 characters.`}</strong><br />{`
You should write an alternative text as if you were telling someone by phone what is in the picture, but try to be brief, because some screen readers cut off alt text after 125 characters`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Don't start with "image of ..." or "picture of ..."`}</strong><br />{`
Screen readers tell their user that they are hearing the alternative text of an image anyway.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Write sentences, not words`}</strong><br />{`
If you have a picture of a dog playing with a ball, your alternative text should look like this:
"A small black wiener-dog playing with a red ball."
Never write something like this to get more keywords: "Dog, doggy, doggo, puppy, wiener-dog, dachshund, play, ball".`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use empty alt attribute for decorative images`}</strong><br />{`
If you have an image just for the looks, you should leave its alt attribute empty, like this: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<img src="image.png" alt="" />`}</code>{`
It is important to include the alt="" part, because in this case screen readers will skip your decorative images.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`If the image has text, write it down`}</strong><br />{`
If an image has text content and it gives extra information to your site, you should include it in the alt attribute.`}</p>
      </li>
    </ul>
    <p className="takeaway">
  Always give proper alt attribute to your images.<br />
  If it's a decorative image, give it an empty alt attribute.<br />
  If you keep these few tips in mind, you will not only make your website more accessible, but you can also get more visitors.
    </p>
    <p>{`You can read more about alt attribut here:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://supercooldesign.co.uk/blog/how-to-write-good-alt-text" rel="noreferrer" target="_blank">How To: Write Good Alt Text | Supercool</a>
      </li>
      <li parentName="ul">
        <a href="https://davidwalsh.name/accessibility-tip-empty-alt-attributes" rel="noreferrer" target="_blank">Accessibility Tip: Empty alt Attributes</a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      